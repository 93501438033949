import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useState, useEffect } from 'react';
import { getSubscription } from '@/services/subscription';
import { queryClient } from '@/App';
import useTableFilter from '../hooks/useTableFilter';
import { exportSuscriptionsService, cancelSybscription } from '../services/subscriptions';
import { OrderCoproductionType } from './OrdersContext';

export const SubscriptionStatus = {
  canceled: { value: 'canceled', label: 'Cancelado', color: 'error' },
  active: { value: 'active', label: 'Ativo', color: 'success' },
  expired: { value: 'expired', label: 'Expirado', color: 'error' },
  trial: { value: 'trial', label: 'Analisando', color: 'warning' },
};

export const SubscriptionPaymentStatus = {
  canceled: { value: 'canceled', label: 'Cancelado', color: 'error' },
  active: { value: 'active', label: 'Ativo', color: 'success' },
  expired: { value: 'expired', label: 'Expirado', color: 'error' },
  trial: { value: 'trial', label: 'Analisando', color: 'warning' },
  processing: { value: 'processing', label: 'Processando', color: 'info' },
  authorized: { value: 'authorized', label: 'Autorizado', color: 'success' },
  paid: { value: 'paid', label: 'Pago', color: 'success' },
  refunded: { value: 'refunded', label: 'Reembolsado', color: 'info' },
  waiting_payment: { value: 'waiting_payment', label: 'Aguardando Pagamento', color: 'warning' },
  refused: { value: 'refused', label: 'Recusado', color: 'error' },
  chargedback: { value: 'chargedback', label: 'Chargeback', color: 'error' },
  in_protest: { value: 'in_protest', label: 'Em Protesto', color: 'warning' },
  partially_paid: { value: 'partially_paid', label: 'Pago Parcialmente', color: 'warning' },
  prechargeback: { value: 'prechargeback', label: 'Pré-Chargeback', color: 'warning' },
  scheduled: { value: 'scheduled', label: 'Agendado', color: 'info' },
  retrying: { value: 'retrying', label: 'Tentando Novamente', color: 'warning' },
  MED: { value: 'MED', label: 'Médio Risco', color: 'warning' },
};

export const PERIODS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  CUSTOM: 'custom',
  ALL_TIME: 'all_time',
};

export const SubscriptionContext = createContext({
  table: {},
  subscriptions: [],
  fetching: false,
  plans: [],
  fetchingPlans: false,
  products: [],
  fetchingProducts: false,
  count: 0,
  cancel: () => {},
  canceling: false,
  exportSubscription: () => {},
  exporting: false,
  deleteSubscription: (id) => {},
  deleting: false,
  tab: 'active',
  setTab: () => {},
  activeCount: 0,
  recurringAmount: 0,
});

export const defaultFilter = {
  period: PERIODS.ALL_TIME,
  startDate: null,
  endDate: null,
  coproductionType: OrderCoproductionType.all.value,
  products: [],
  plans: [],
  paymentMethods: [],
  status: ['active'],
};

const SubscriptionsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
    defaultFilter,
  });

  const [tab, setTab] = useState('active');
  const [createAt, setCreateAt] = useState('');
  const [startDate, setStartDate] = useState(new Date('2024-12-16'));
  const [endDate, setEndDate] = useState(new Date('2024-12-23'));

  const { enqueueSnackbar } = useSnackbar();

  const buildCreatedAtFilter = (start, end) => {
    const params = new URLSearchParams();

    if (start) {
      const formattedStartDate = start.toISOString().split('T')[0];
      params.append('createdAt__gte', formattedStartDate);
    }

    if (end) {
      const formattedEndDate = `${end.toISOString().split('T')[0]}T23:59:59-03:00`;
      params.append('createdAt__lt', formattedEndDate);
    }

    const queryString = params.toString();
    setCreateAt(queryString);
    return queryString;
  };

  useEffect(() => {
    buildCreatedAtFilter(startDate, endDate);
  }, [startDate, endDate]);

  const { data: subscriptions = [], isFetching: fetchingSubscription } = useQuery({
    queryFn: () =>
      getSubscription({
        page: table.page,
        limit: table.limit,
        search: table.search,
        createAt,
        ...table.filter,
      }),
    queryKey: ['subscription', table.filter, table.search, createAt],
    initialData: [],
  });

  const { data: plans = [], isFetching: fetchingPlans } = useQuery({
    queryFn: () => ({
      plans: [
        { id: '1', name: 'Plano 1' },
        { id: '2', name: 'Plano 2' },
      ],
    }),
    queryKey: ['plans'],
    select: (data) => data.plans.map((plan) => ({ value: plan.id, label: plan.name })),
    initialData: { plans: [] },
  });

  const { mutateAsync: exportSubscription, isLoading: exporting } = useMutation({
    mutationFn: ({ extension }) =>
      exportSuscriptionsService({
        extension,
        page: table.page,
        limit: table.limit,
        ...table.filter,
      }),
    onSuccess: () => enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' }),
    onError: () => enqueueSnackbar('Erro ao realizar exportação', { variant: 'error' }),
  });

  const { mutateAsync: cancel, isLoading: canceling } = useMutation(
    ({ id }) =>
      () =>
        true,
    {
      onSuccess: () =>
        enqueueSnackbar('Cancelamento realizado com sucesso', { variant: 'success' }),
      onError: () => enqueueSnackbar('Erro ao realizar cancelamento', { variant: 'error' }),
    }
  );

  const { mutateAsync: deleteSubscription, isLoading: deleting } = useMutation({
    mutationFn: ({ id }) => cancelSybscription({ id }),
    onSuccess: () => {
      enqueueSnackbar('Assinatura deletada com sucesso', { variant: 'success' });
      queryClient.invalidateQueries(['subscription']);
    },
    onError: () => enqueueSnackbar('Erro ao deletar assinatura', { variant: 'error' }),
  });

  const value = useMemo(
    () => ({
      table,
      subscriptions,
      fetchingSubscription,
      plans,
      fetchingPlans,
      cancel,
      canceling,
      exportSubscription,
      exporting,
      deleteSubscription,
      deleting,
      tab,
      setTab,
      buildCreatedAtFilter,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [
      table,
      subscriptions,
      fetchingSubscription,
      plans,
      fetchingPlans,
      cancel,
      canceling,
      exportSubscription,
      exporting,
      deleteSubscription,
      deleting,
      tab,
      setTab,
      startDate,
      endDate,
    ]
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

SubscriptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionsProvider;
