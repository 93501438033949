import { downloadFile } from '../../utils/files';

const { AuthenticatedHttp } = require('../../http/axios');

export const getSuscriptionsService = async ({ page, limit, search, status, ...filter }) =>
  AuthenticatedHttp.get(`/subscriptions/`, {
    params: {
      page,
      limit,
      search,
      status,
      ...filter,
    },
  });

export const exportSuscriptionsService = async ({
  extension,
  page,
  limit,
  search,
  status,
  ...filter
}) =>
  AuthenticatedHttp.get(`/subscriptions/export/${extension}`, {
    params: {
      page,
      limit,
      search,
      status,
      ...filter,
    },
  }).then((response) => {
    downloadFile({
      url: window.URL.createObjectURL(new Blob([response.data])),
      filename: `subscriptions.${extension}`,
    });
  });
export const cancelSybscription = async ({ id }) =>
  AuthenticatedHttp.post(`/subscriptions/${id}/cancel/`).then((response) => {
    console.log('Resposta do cancelamento:', response);
    return response; // Retorna a resposta para quem chamou
  });
