import { Navigate, Outlet, useRoutes, useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import VerifyModal from '@/sections/auth/VerifyModal';
// auth
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import AuthGuard from '../auth/AuthGuard';
import AffiliatesProvider from '../contexts/AffiliatesContext';
import { CheckoutBuilderProvider } from '../contexts/CheckoutBuilderContext';
import CollaboratorsProvider from '../contexts/CollaboratorsContext';
import EditProductProvider from '../contexts/EditProductContext';
import CoursesProvider from '../contexts/MemberCoursesContext';
import NotificationsProvider from '../contexts/NotificationsContext';
import OrdersProvider from '../contexts/OrdersContext';
import ProductsProvider from '../contexts/ProductsContext';
import ReportAffiliateProvider from '../contexts/ReportAffiliateContext';
import ReportBallanceProvider from '../contexts/ReportBalanceContext';
import ReportCoProductionsProvider from '../contexts/ReportCoProductionsContext';
import ReportProductsProvider from '../contexts/ReportProductsContext';
import SubscriptionsProvider from '../contexts/SubscriptionContext';
import WithdrawalsProvider from '../contexts/WithdrawalsContext';
import ProductModulesProvider from '../contexts/ProductModulesContext';
import CheckoutBuilderLayout from '../layouts/checkout-builder/CheckoutBuilderLayout';
import StudentLayout from '../layouts/dashboard/StudentLayout';
import WatchCourse from '../pages/course/CourseWatch';
import ProductMembersArea from '../pages/dashboard/ProductMembersArea';
import StudentNotifications from '../pages/student/StudentNotifications';
import {
  AboutPage,
  AffiliateInvitePage,
  AffiliatesPage,
  Apps,
  CheckoutBuilder,
  Collaborators,
  ComingSoonPage,
  Contact,
  Courses,
  DashboardHomePage,
  EditProduct,
  FaqsPage,
  Financial,
  FinancialCreditCard,
  FinancialCreditCardTransactions,
  FinancialPixKeys,
  // Auth
  LoginPage,
  MaintenancePage,
  MySales,
  NewPasswordPage,
  CreatePasswordPage,
  NotificationsPage,
  Page403,
  Page404,
  //
  Page500,
  PaymentPage,
  PricingPage,
  Products,
  Profile,
  RegisterPage,
  ReportAbandonedSales,
  ReportAffiliatesRevenue,
  ReportCoProductionRevenue,
  ReportCoursesEngagement,
  ReportFinancialEstimate,
  ReportProductsRevenue,
  Reports,
  ResetPasswordPage,
  StudentProfile,
  Subscriptions,
  VerifyCodePage,
  // Refund
  RefundHome,
  RefundPurchases,
  RefundPurchase,
  // Coupons
  CouponsPage,
  // Members,
  MembersDiscordFinishConfigPage,
  SubscriptionsTable,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const user = queryClient.getQueryData(['me']);

  const location = useLocation();

  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);

  LogRocket.identify(user?.email, {
    name: user?.first_name,
    email: user?.email,
  });

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        onError: (error) => {
          const message = error?.response?.data?.detail || 'Ocorreu um erro ao carregar os dados';
          enqueueSnackbar(message, {
            variant: 'error',
          });
        },
      },
      mutations: {
        onError: (error) => {
          enqueueSnackbar('Ocorreu um erro ao processar a requisição', {
            variant: 'error',
          });
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes('dashboard')) {
      localStorage.setItem('next', location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  const isOpenVerifyModal = queryClient.getQueryData(['isOpenVerifyModal']);

  const onCloseVerifyLoginModal = () => {
    queryClient.setQueryData(['isOpenVerifyModal'], false);
  };

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            {
              path: 'create-password',
              element: <CreatePasswordPage />,
            },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    {
      path: 'affiliate/invite/:id',
      element: (
        <AuthGuard>
          <AffiliateInvitePage />
        </AuthGuard>
      ),
    },

    {
      path: 'student',
      element: (
        <AuthGuard>
          <StudentLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'profile',
          element: <StudentProfile />,
        },
        {
          path: 'notifications',
          element: (
            <NotificationsProvider>
              <StudentNotifications />
            </NotificationsProvider>
          ),
        },
        {
          path: 'courses',
          element: (
            <CoursesProvider>
              <Courses />
            </CoursesProvider>
          ),
        },
        {
          path: 'courses/:course_id',
          element: <WatchCourse />,
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <>
            <DashboardLayout />
            <VerifyModal
              open={isOpenVerifyModal}
              onClose={onCloseVerifyLoginModal}
              isToValidateEmail={false}
              isToValidateWhatsapp
              isToLogoutOnClose
              onSuccessWhatsapp={onCloseVerifyLoginModal}
            />
          </>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <DashboardHomePage /> },
        {
          path: 'my-sales',
          element: (
            <OrdersProvider>
              <MySales />
            </OrdersProvider>
          ),
        },
        {
          path: 'subscriptions',
          element: (
            <SubscriptionsProvider>
              <SubscriptionsTable />
            </SubscriptionsProvider>
          ),
        },
        {
          path: 'collaborators',
          element: (
            <CollaboratorsProvider>
              <Collaborators />
            </CollaboratorsProvider>
          ),
        },
        {
          path: 'financial',
          element: (
            <WithdrawalsProvider>
              <Outlet />
            </WithdrawalsProvider>
          ),
          children: [
            {
              path: '',
              element: <Financial />,
            },
            {
              path: 'credit-card',
              element: <FinancialCreditCard />,
            },
            {
              path: 'credit-card/:id',
              element: <FinancialCreditCardTransactions />,
            },
            {
              path: 'pix-keys',
              element: <FinancialPixKeys />,
            },
          ],
        },
        { path: 'apps', element: <Apps /> },
        { path: 'profile', element: <Profile /> },
        {
          path: 'notifications',
          element: (
            <NotificationsProvider>
              <NotificationsPage />
            </NotificationsProvider>
          ),
        },

        {
          path: 'affiliates',
          element: (
            <AffiliatesProvider type="affiliates">
              <AffiliatesPage />
            </AffiliatesProvider>
          ),
        },
        {
          path: 'products',
          element: (
            <ProductsProvider>
              <Products />
            </ProductsProvider>
          ),
        },
        {
          path: 'products/:id',
          element: (
            <EditProductProvider>
              <Outlet />
            </EditProductProvider>
          ),
          children: [
            {
              path: 'affiliates/:short_id',
              element: <EditProduct />,
            },
            {
              path: 'edit',
              element: <EditProduct />,
            },
            {
              path: 'members-area',
              element: (
                <ProductModulesProvider>
                  <ProductMembersArea />
                </ProductModulesProvider>
              ),
            },
          ],
        },
        {
          path: 'products/:id/:short_user_id',
          element: (
            <EditProductProvider>
              <Outlet />
            </EditProductProvider>
          ),
          children: [
            {
              path: 'edit',
              element: <EditProduct />,
            },
            {
              path: 'members-area',
              element: <ProductMembersArea />,
            },
          ],
        },
        { path: 'reports', element: <Reports /> },
        { path: 'reports/abandoned-sales', element: <ReportAbandonedSales /> },
        {
          path: 'reports/affiliates-revenue',
          element: (
            <ReportAffiliateProvider>
              <ReportAffiliatesRevenue />
            </ReportAffiliateProvider>
          ),
        },
        {
          path: 'reports/coproduction-revenue',
          element: (
            <ReportCoProductionsProvider>
              <ReportCoProductionRevenue />
            </ReportCoProductionsProvider>
          ),
        },
        { path: 'reports/courses-engagement', element: <ReportCoursesEngagement /> },
        {
          path: 'reports/financial-estimate',
          element: (
            <ReportBallanceProvider>
              <ReportFinancialEstimate />
            </ReportBallanceProvider>
          ),
        },
        {
          path: 'reports/products-revenue',
          element: (
            <ReportProductsProvider>
              <ReportProductsRevenue />
            </ReportProductsProvider>
          ),
        },
        {
          path: 'coupons',
          element: <CouponsPage />,
        },
      ],
    },

    {
      element: (
        <CheckoutBuilderProvider>
          <CheckoutBuilderLayout />
        </CheckoutBuilderProvider>
      ),
      children: [
        {
          path: 'checkout-builder/:id',
          element: <CheckoutBuilder />,
        },
      ],
    },

    // Main Routes
    {
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },

    // Refund
    {
      path: 'refund',
      element: <RefundHome />,
    },
    {
      path: 'refund/purchases',
      element: <RefundPurchases />,
    },
    {
      path: 'refund/purchases/:id',
      element: <RefundPurchase />,
    },

    // Members
    {
      element: <CompactLayout />,
      children: [
        {
          path: 'members/discord/finish-config',
          element: (
            <AuthGuard>
              <MembersDiscordFinishConfigPage />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
