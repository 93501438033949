import PropTypes from 'prop-types';
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
};

export default function ConfirmDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          data-testid="confirm-dialog-close-button"
          onClick={onClose}
        >
          Cancelar
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}
