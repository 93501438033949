import { AuthenticatedHttp } from '../../http/axios';

export const getSubscription = async ({ page = 1, limit = 10, search, createAt, ...rest }) =>
  AuthenticatedHttp.get(`/subscriptions/?${createAt}`, {
    params: {
      page,
      limit,
      search,
      expand: 'parent_order,customer,product,offer,orders',
      ...rest,
    },
  }).then(({ data }) => ({
    ...data,
    results: data.results,
    count: data.count,
  }));
